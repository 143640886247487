// ========= REACT =========
import React, { useEffect, useState } from "react";

// ========= COMPONENTS =========
import styled from "styled-components";
import { Line } from "@nivo/line";
import { Pie } from "@nivo/pie";
import { ScatterPlot } from "@nivo/scatterplot";
import { Stream } from "@nivo/stream";
import { Link } from "gatsby";

// ========= UTILITIES =========
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMetrics } from "../app/firebase";
import { format, isBefore, addHours } from "date-fns";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";

// ========= STYLE =========
import "normalize.css";
import "../styles/main.css";
import "../styles/typography.css";

// ========= STYLED COMPONENTS =========
const Container = styled.div`
	display: block;

	height: 100vh;
	width: 100vw;
	overflow-y: scroll;

	text-align: center;
`;

const Headline = styled.h1`
	margin: 1em;
`;

const Element = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 75vw;
	margin: 0 auto;

	padding: 3em;
	border-top: 2px solid black;
`;

// ========= MAIN =========
const MetricsPage = () => {
	// get all metrics
	const [metrics, setMetrics] = useState({});
	const [businesses, setBusinesses] = useState([]);
	const [scores, setScores] = useState([]);
	const [times, setTimes] = useState([]);
	useEffect(() => {
		(async () => {
			const fromDB = await getMetrics();

			const _businesses = [];
			const _scores = [];
			const _times = [];
			fromDB.forEach((player) => {
				_businesses.push({
					id: player.id,
					business: player.business,
				});
				_scores.push({
					id: player.id,
					scores: {
						cloud: player.cloud,
						player: player.player,
					},
				});
				_times.push({
					id: player.id,
					timestamp: player.timestamp,
				});
			});

			setMetrics(fromDB);
			setBusinesses(_businesses);
			setScores(_scores);
			setTimes(_times);
		})();
	}, []);

	// set business pie chart data
	const [businessesData, setBusinessesData] = useState([]);
	useEffect(() => {
		const vals = {};
		businesses.forEach((biz) => {
			if (biz.business in vals) {
				vals[biz.business] += 1;
			} else {
				vals[biz.business] = 1;
			}
		});

		const data = [];
		Object.keys(vals).forEach((key) => {
			const color = (() => {
				switch (key) {
					case `The Slice is Right`:
						return COLORS.defaults.yellow;
					case `Scoop Dreams`:
						return COLORS.defaults.red;
					case `Sugar Spinners`:
						return COLORS.defaults.green;
					default:
						return COLORS.defaults.blue;
				}
			})();

			data.push({
				id: key,
				label: key,
				value: vals[key],
				color,
			});
		});

		setBusinessesData(data);
	}, [businesses]);

	// set wins/losses pie chart data
	const [winsData, setWinsData] = useState([]);
	useEffect(() => {
		const data = [
			{
				id: `Player Wins`,
				key: `Player Wins`,
				value: 0,
				color: COLORS.defaults.yellow,
			},
			{
				id: `Cloud Wins`,
				key: `Cloud Wins`,
				value: 0,
				color: COLORS.defaults.blue,
			},
		];
		scores.forEach((score) => {
			if (score.scores.player > score.scores.cloud) {
				data[0].value++;
			} else {
				data[1].value++;
			}
		});

		setWinsData(data);
	}, [scores]);

	// set time line chart data
	const [timeData, setTimeData] = useState([]);
	useEffect(() => {
		// if the data hasn't been loaded
		if (times.length === 0) return;

		// TEMP: remove old metrics versions
		const filtered = times.filter(
			(player) => typeof player.timestamp !== `undefined`
		);

		// sort the data by timestamp
		filtered.sort((first, second) => {
			if (first.timestamp > second.timestamp) return 1;
			return -1;
		});

		// get the first and last timestamp
		const firstDate = new Date(filtered[0].timestamp);
		const lastDate = new Date(
			filtered[filtered.length - 1].timestamp
		);

		// set format string for bucketing

		const formatString = `YY-MM-DD HH:00:00`;

		// create a bucket for each hour between the first and last timestamp
		const buckets = {};
		let dateIterator = firstDate;
		while (isBefore(dateIterator, lastDate)) {
			buckets[format(dateIterator, formatString)] = 0;
			dateIterator = addHours(dateIterator, 1);
		}
		buckets[format(dateIterator, formatString)] = 0;

		// increment the value of each bucket for every timestamp that fits
		filtered.forEach((player) => {
			const time = format(
				new Date(player.timestamp),
				formatString
			);
			buckets[time] += 1;
		});

		// format the data for nivo
		const bucketData = [];
		Object.keys(buckets).forEach((bucket) => {
			bucketData.push({ x: bucket, y: buckets[bucket] });
		});
		setTimeData([
			{
				id: `visitors`,
				color: COLORS.defaults.blue,
				data: bucketData,
			},
		]);
	}, [times]);

	// set cloud vs player score scatter chart data
	const [vsData, setVsData] = useState([]);
	useEffect(() => {
		const data = [{ id: `Scores`, data: [] }];
		scores.forEach((score) => {
			data[0].data.push({
				x: score.scores.player,
				y: score.scores.cloud,
			});
		});

		setVsData(data);
	}, [scores]);

	const [
		distributionStreamData,
		setDistributionStreamData,
	] = useState([]);
	useEffect(() => {
		if (scores.length === 0) return;

		const cloudBuckets = {};
		const playerBuckets = {};

		const round = (val) => Math.round(val / 1000) * 1000;

		scores.forEach((score) => {
			if (round(score.scores.player) in playerBuckets) {
				playerBuckets[round(score.scores.player)] += 1;
			} else {
				playerBuckets[round(score.scores.player)] = 1;
			}

			if (round(score.scores.cloud) in cloudBuckets) {
				cloudBuckets[round(score.scores.cloud)] += 1;
			} else {
				cloudBuckets[round(score.scores.cloud)] = 1;
			}
		});

		// get all bucket values
		const buckets = Object.keys(cloudBuckets)
			.map((key) => parseInt(key))
			.concat(
				Object.keys(playerBuckets).map((key) => parseInt(key))
			)
			.sort((first, second) => {
				if (parseInt(first) > parseInt(second)) return 1;
				return -1;
			});
		const lowestBucket = buckets[0];
		const highestBucket = buckets[buckets.length - 1];

		for (let i = lowestBucket; i <= highestBucket; i += 1000) {
			if (!(i in playerBuckets)) playerBuckets[i] = 0;
			if (!(i in cloudBuckets)) cloudBuckets[i] = 0;
		}

		const streamData = Object.keys(cloudBuckets).map((key) => ({
			key,
			cloud: cloudBuckets[key],
			player: playerBuckets[key],
		}));
		setDistributionStreamData(streamData);
	}, [scores]);

	// ========= RETURN =========

	const convertValueToPercentString = (d) =>
		`${Math.round(
			(Math.round((d.value / Object.keys(metrics).length) * 100) /
				100) *
			100
		)}%`;

	const commonPieProperties = {
		width: 800,
		height: 400,
		margin: {
			top: 50,
			right: 220,
			bottom: 50,
			left: 220,
		},

		innerRadius: 0.5,
		padAngle: 2,

		colorBy: (d) => d.color,

		radialLabelsTextXOffset: 6,
		radialLabelsTextColor: `#000000`,
		radialLabelsLinkOffset: 0,
		radialLabelsLinkDiagonalLength: 16,
		radialLabelsLinkHorizontalLength: 24,
		radialLabelsLinkStrokeWidth: 2,
		radialLabelsLinkColor: `inherit`,

		slicesLabelsSkipAngle: 5,

		slicesLabelsTextColor: `#ffffff`,

		isInteractive: true,

		theme: {
			labels: {
				text: {
					fontSize: 18,
				},
			},
		},
	};

	const visitorLineProperties = {
		width: 800,
		height: 400,
		margin: {
			top: 50,
			right: 10,
			bottom: 120,
			left: 50,
		},
		xScale: {
			type: `time`,
			format: `%Y-%m-%d %H:%M:%S`,
			precision: `hour`,
		},
		yScale: {
			type: `linear`,
			stacked: false,
			min: 0,
			max: `auto`,
		},
		axisTop: null,
		axisRight: null,
		axisBottom: {
			orient: `bottom`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 45,
			legend: `hour`,
			legendOffset: 100,
			legendPosition: `middle`,
			format: `%b %d @ %I %p`,
			tickValues: 20,
		},
		axisLeft: {
			orient: `left`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: `visitor count`,
			legendOffset: -40,
			legendPosition: `middle`,
		},
		dotSize: 10,
		dotColor: COLORS.blue[`600`],
		dotBorderWidth: 2,
		dotBorderColor: `#ffffff`,
		enableDotLabel: true,
		dotLabel: `y`,
		dotLabelYOffset: -12,
		enableGridX: false,
		colorBy: (d) => d.color,
		curve: `monotoneX`,
		tooltip: (d) => (
			<div>
				<strong>{d.data[0].data.y} visitors</strong>
				<div>
					{format(d.id, `MM/DD/YY`)}
					<span> at </span>
					{format(d.id, `h:00 a`)}
				</div>
			</div>
		),
	};

	const vsScatterProperties = {
		width: 800,
		height: 400,
		margin: {
			top: 50,
			right: 10,
			bottom: 50,
			left: 70,
		},
		xScale: {
			type: `linear`,
			min: 0,
			max: 99999,
		},
		yScale: {
			type: `linear`,
			min: 0,
			max: 99999,
		},
		axisTop: null,
		axisRight: null,
		axisBottom: {
			orient: `bottom`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: `player`,
			legendPosition: `middle`,
			legendOffset: 46,
		},
		axisLeft: {
			orient: `left`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: `cloud`,
			legendPosition: `middle`,
			legendOffset: -50,
		},
		symbolSize: 10,
		colorBy: (d) =>
			d.x > d.y ? COLORS.defaults.yellow : COLORS.defaults.blue,
		animate: true,
		motionStiffness: 90,
		motionDamping: 15,
		legends: [],
		tooltip: (d) => (
			<div>
				<span>Cloud: </span>
				<strong>{d.y}</strong>
				<br />
				<span>Player: </span>
				<strong>{d.x}</strong>
			</div>
		),
	};

	const distributionStreamProperties = {
		width: 800,
		height: 400,
		margin: {
			top: 50,
			right: 50,
			bottom: 50,
			left: 50,
		},
		axisTop: null,
		axisRight: null,
		axisBottom: {
			orient: `bottom`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 45,
			legend: `score`,
			legendOffset: 50,
			legendPosition: `center`,
			format: (val) => distributionStreamData[val].key,
		},
		axisLeft: {
			orient: `left`,
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: `count`,
			legendOffset: -40,
			legendPosition: `center`,
			tickValues: 0,
		},

		fillOpacity: 0.85,
		animate: true,
		motionStiffness: 90,
		motionDamping: 15,
		enableGridX: false,
		enableGridY: false,
		offsetType: `diverging`,
		curve: `step`,
		colors: (d) =>
			d ? COLORS.defaults.blue : COLORS.defaults.yellow,
	};

	return (
		<Container>
			<Headline>Delivery Tycoon Analytics</Headline>
			<Element>
				<h2>Total Number of Visitors</h2>
				<h4>{Object.keys(metrics).length}</h4>
			</Element>
			<Element>
				<h2>Business Choices</h2>
				<Pie
					data={businessesData}
					{...commonPieProperties}
					sliceLabel={convertValueToPercentString}
				/>
			</Element>
			<Element>
				<h2>Wins vs. Losses</h2>
				<Pie
					data={winsData}
					{...commonPieProperties}
					sliceLabel={convertValueToPercentString}
					tooltip={(d) => (
						<div>
							<strong>
								{d.value} {d.key}
							</strong>
							<span> against the cloud</span>
						</div>
					)}
				/>
			</Element>
			<Element>
				<h2>Visitors per Hour</h2>
				<Line data={timeData} {...visitorLineProperties} />
			</Element>
			<Element>
				<h2>Cloud vs Player Scores</h2>
				<ScatterPlot data={vsData} {...vsScatterProperties} />
			</Element>
			<Element>
				<h2>Score Distributions</h2>
				<Stream
					keys={[`player`, `cloud`]}
					data={distributionStreamData}
					{...distributionStreamProperties}
				/>
			</Element>
			<Element>
				<p>
					<Link to="/">Play Game</Link>
				</p>
			</Element>
		</Container>
	);
};

export default MetricsPage;
